import { useInView } from 'framer-motion'
import React, { useContext, useEffect, useRef } from 'react'
import ColorContext from '../providers/ColorContainer'

function BgColorContainer({ children }) {
    const ref = useRef(null)
    const isInView = useInView(ref, {
        margin: "0px 1000px -1000px 0px"
    })
    const { setBgColor } = useContext(ColorContext)

    useEffect(() => {
        if (isInView) {
            setBgColor('black')
        } else {
            setBgColor('white')
        }
    }, [isInView])

    return (
        <div ref={ref}>
            {children}
        </div>
    )
}

export default BgColorContainer