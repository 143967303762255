import React from 'react'

function SectionTitle({ text }) {
    return (
        <p className='text-primary text-3xl font-bold'>
            {text}
        </p>
    )
}

export default SectionTitle