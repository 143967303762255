import React from 'react';
import { PiCaretCircleDownThin } from "react-icons/pi";
import Formulario from '../Home/Formulario';

const ContactoPage = () => {
    const bottomRef = React.useRef();
    const onClick = () => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className='bg-primary  w-full h-min  p-4 md:p-0  overflow-hidden'>
            <div className='container py-5 md:py-10 w-full h-screen flex flex-col justify-center items-center gap-10 relative'>
            {/* <div className='container w-full p-5 md:py-16 '> */}
                <p className='2xl:text-7xl text-3xl text-secondary opacity-50 md:leading-normal 2xl:leading-[68px]'>Conversemos y juntos impulsemos la transformación digital que llevará a tu empresa al siguiente nivel.</p>
                <p className='text-xl text-secondary opacity-50 w-full md:w-1/2 self-start'>Estamos aquí para ayudarte a llevar tu negocio al siguiente nivel. Ya sea que necesites asesoría,
                    un nuevo enfoque digital o  simplemente quieras conocer más sobre nuestros servicios. no
                    dudes en contactarnos. ¡Con Awna, cada conversación es una oportunidad de crecimiento!
                </p>
                <div className="flex items-start justify-center absolute bottom-0  ">
                    <div className="flex flex-col items-center "> {/* Contenedor para el texto y el botón */}
                        <p className='text-xl text-secondary opacity-71 mb-2 font-bold'>Hablemos</p> {/* Margen inferior para espacio */}
                        <PiCaretCircleDownThin onClick={onClick} className='w-[64px] h-[64px] text-secondary opacity-35' />
                    </div>
                </div>
            </div>
            <div className="container mt-32 " ref={bottomRef}>
                <Formulario />
            </div>
        </div>
    )
}

export default ContactoPage