import React, { useContext } from 'react';
import SectionTitle from '../../ui/SectionTitle';
import CursorContext from '../../providers/Cursor';
import ServiciosData from '../../../Data/Servicios.json';
import ServiceItem from '../../ui/ServiceItem';
import ImgHover from '../../../assets/img/Hoverimagen.png';
import { Button } from 'antd';
import { NavLink } from 'react-router-dom';

function ListaServicios () {
    const { blackSectionEnter, blackSectionExit } = useContext(CursorContext)

    return (
        <div onMouseEnter={blackSectionEnter} onMouseLeave={blackSectionExit} className="bg-black pb-32 relative overflow-hidden ">
            <div className='container pl-5 md:pl-0 flex flex-col md:flex-row'>
                <div className='flex-grow'>
                    <img src={'https://awna.s3.sa-east-1.amazonaws.com/whitegrain.webp'} className='absolute inset-0 w-full h-full object-cover z-0 opacity-40 blur-70' alt="Fondo" />
                    <div className='w-[300px] py-10'>
                        <div className="flex flex-col md:flex-row md:items-center items-start gap-10 rounded-lg mt-3 -mb-3">
                            <SectionTitle text='Servicios' />
                        </div>
                    </div>

                    <div className="flex justify-left mt-20 relative z-10 bg-transparent">
                        <ul className="list-none flex flex-col gap-5">
                            {
                                ServiciosData.map((servicio) => (
                                    <div key={servicio.id}>
                                        <ServiceItem nombre={servicio.nombre} index={servicio.id} />
                                        <div className='inline-flex w-full border-dashed border-b-2 border-[#EDEEE9] h-1 opacity-40 overflow-visible'></div>
                                    </div>
                                ))
                            }
                        </ul>

                    </div>
                    <div>
                        <NavLink to={'/contact'}>
                            <Button className='bg-primary rounded-none !text-secondary text-base py-5 md:w-min px-10 cursor-none mt-24'>
                                Cotizar
                            </Button>
                        </NavLink>

                        
                    </div>
                </div>

                <div className='flex-none  -mr-[160px] md:w-[45%] h-screen relative hidden md:block'>
                    <img src={"https://awna.s3.sa-east-1.amazonaws.com/Hoverimagen.png"} className='w-full h-full object-cover' />
                </div>
            </div>

        </div>
  )
}

export default ListaServicios