import React from 'react';
import ContactoPage from '../Sections/Contact/ContactoPage';
import Container from '../Layout/Container';
import Footer from '../Sections/Home/Footer';

function Contact() {
    return (
        <Container>
            <ContactoPage />
            <Footer/>
        </Container>
    )
}

export default Contact