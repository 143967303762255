import React, { useContext } from 'react';
import { SlArrowRight } from "react-icons/sl";
import SectionTitle from '../../ui/SectionTitle';
import CursorContext from '../../providers/Cursor';
import ServiciosData from '../../../Data/Servicios.json';
import ServiceItem from '../../ui/ServiceItem';
import BgColorContainer from '../../Layout/BgColorContainer';
import MoleculaServicio from '../../../assets/img/MoleculaServicio.webp';


function Servicios() {

  const { blackSectionEnter, blackSectionExit } = useContext(CursorContext)

  return (
    <BgColorContainer>
      <div onMouseEnter={blackSectionEnter} onMouseLeave={blackSectionExit} className="bg-black h-min relative overflow-hidden">
        <div className='container p-5 md:p-0'>
          <img src={'https://awna.s3.sa-east-1.amazonaws.com/whitegrain.webp'} className='absolute inset-0 w-full h-full object-cover z-0 opacity-40 blur-70' alt="Fondo" />
          <div className='w-full py-10' >
            <div className="flex flex-col md:flex-row md:items-center items-start gap-10 rounded-lg mt-3 -mb-3">
              <SectionTitle text='Servicios' />
              <SlArrowRight className='text-primary w-7 h-13 hidden md:block ' />
              <p className="text-xl text-primary opacity-50 md:w-1/2 md:mt-10 text-justify ">
                Soluciones innovadoras en transformación digital. optimizando procesos <br className='hidden md:block' />
                y mejorando la eficiencia
                a través de la tecnología.
              </p>

            </div>
          </div>
          <div className='inline-flex w-full border-dashed border-b-2 border-[#EDEEE9] h-1 opacity-40'></div>
          <div className="flex justify-left md:mt-16 2xl:h-[800px] md:h-[600px] py-20 relative z-90 ">
            <ul className="list-none flex flex-col gap-10 z-95">
              {
                ServiciosData.map((servicio) => {
                  return (
                    <ServiceItem key={servicio.id} nombre={servicio.nombre} index={servicio.id} />
                  )
                })
              }
            </ul>
            <img src={'https://awna.s3.sa-east-1.amazonaws.com/Moleculas/MoleculaServicio.webp'} className='absolute right-0 2xl:w-auto md:w-1/2 z-0 hidden md:block' />
          </div>
        </div>
      </div>
    </BgColorContainer>
  )
}

export default Servicios