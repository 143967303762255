import React, { useContext, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Navbar from './Navbar'
import CursorContext from '../providers/Cursor'


function Container({ children }) {

    const { cursorVariant } = useContext(CursorContext)

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    })


    useEffect(() => {
        const mouseMove = e => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }
        window.addEventListener("mousemove", mouseMove)

        return () => {
            window.removeEventListener("mousemove", mouseMove)
        }
    }, [])

    const variants = {
        default: {
            x: mousePosition.x - 16,
            y: mousePosition.y - 16,
            transition: {
                type: "smooth",
                duration: 0,
            },
        },
        select: {
            backgroundColor: "#333333",
            x: mousePosition.x - 16,
            y: mousePosition.y - 16,
            width: 30,
            height: 30,
            transition: {
                type: "smooth",
                duration: 0,
            },
        },
        blackSection: {
            backgroundColor: "#EDEEE9",
            x: mousePosition.x - 16,
            y: mousePosition.y - 16,
            width: 30,
            height: 30,
            transition: {
                type: "smooth",
                duration: 0,
            },
            border: "0px"
        },
        navbarSection: {
            backgroundColor: "#EDEEE9",
            x: mousePosition.x - 16,
            y: mousePosition.y - 16,
            width: 30,
            height: 30,
            transition: {
                type: "smooth",
                duration: 0,
            },
            border: "0px"
        }
    }
    const ScrollToTop = () => {
        window.scrollTo(0, 0, { behavior: "smooth" })
    }


    useEffect(() => {
        ScrollToTop()
    }, [])

    return (
        <div className='w-full relative' >
            <Navbar />
            <motion.div className='custom-cursor-scroll border-2 ' variants={variants}
                animate={cursorVariant}
            >
                {
                    cursorVariant == "default" && <p>Scroll</p>
                }
            </motion.div>
            {children}
        </div >
    )
}

export default Container