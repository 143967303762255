import React, { useContext } from 'react';
import Message from './Message';


function Hero() {

    return (
        <div className='bg-black w-full FondoAboutUs'>
            <div className='container relative flex items-center justify-center h-min flex-col gap-32 p-5 md:px-0 md:pb-72 pt-44 '>
                <h1 className='text-2xl 2xl:text-7xl lg:text-5xl text-primary  leading-relaxed md:leading-[68px] '>
                    Emergemos como una propuesta innovadora en
                    una industria en constante evolución, donde la
                    tecnología y la transformación digital son claves
                    para destacar.
                </h1>
                <p className='text-base md:text-xl text-justify text-primary leading-relaxed w-full'>
                    <div className='inline-flex bg-primary text-black px-2'>
                        Somos más que una consultora; somos tu equipo extendido preparado
                    </div>
                    <br />
                    <p className='text-primary text-justify md:w-2/5'>
                        para desarrollar estrategias 360° que cubren todo el proceso, desde
                        la planificación hasta la integración integral, sin que tengas que
                        depender de diferentes agencias para cada proceso.
                    </p>
                </p>
                <img src={'https://awna.s3.sa-east-1.amazonaws.com/Moleculas/moleculaAboutus.png'} alt="MoleculaAboutus" className='absolute 2xl:w-auto md:w-1/2 -bottom-52 right-0 hidden md:block' />
            </div >
            <Message />
        </div >

    )
}

export default Hero