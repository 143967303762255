import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img from "../../../assets/img/people.webp";
import { useEffect, useState } from "react";

// data
const dataslide = [
  {
    id: "1",
    title: "Pasión por nuestros Clientes",
    url: "https://awna.s3.sa-east-1.amazonaws.com/promesas/Pasion+por+nuestros+clientes.webp",
  },
  {
    id: "2",
    title: "Trabajamos con compromiso y lealtad",
    url: "https://awna.s3.sa-east-1.amazonaws.com/promesas/Trabajamos+con+compromiso+y+lealtad.webp",
  },
  {
    id: "3",
    title: "No Engañamos a nuestros clientes",
    url: "https://awna.s3.sa-east-1.amazonaws.com/promesas/No+enganiamos+a+nuestros+clientes.webp",
  },
  {
    id: "4",
    title: "valientes y radicales",
    url: "https://awna.s3.sa-east-1.amazonaws.com/promesas/valientes+y+radicales.webp",
  },
  {
    id: "5",
    title: "Desarrollamos soluciones rapidas y simples",
    url: "https://awna.s3.sa-east-1.amazonaws.com/promesas/Desarrollamos+soluciones+rapidas+y+simples.webp",
  },
  {
    id: "6",
    title: "Buscamos excelencia para nuestros clientes ",
    url: "https://awna.s3.sa-east-1.amazonaws.com/promesas/Buscamos+excelencia+para+nuestros+clientes+.webp",
  },
];

function Carrousel({ slider }) {
  const isMobile = window.innerWidth < 768;

  // const [display, setDisplay] = useState(true);
  // const [width, setWidth] = useState(600);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: 1,
    arrows: false,
    centerMode: isMobile ? false : true,
    // autoplay: true,
    // speed: 2000,
    // autoplaySpeed: 1000,
    // cssEase: "linear",
  };

  return (
    <>
      <Slider ref={slider} {...settings}>
        {dataslide.map((item, i) => {
          return (
            <div key={i} className="flex justify-center items-center">
              <div className="bg-transparent border border-gray-200 rounded-lg overflow-hidden shadow-lg">
                <a href="#">
                  <img
                    className="w-full h-64 object-cover"
                    src={item.url}
                    alt={item.title}
                  />
                </a>
                <div className="mt-4 p-4">
                  <p className="text-lg font-bold text-white text-center">
                    {item.title}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
}

export default Carrousel;
