import React from 'react';
import Formulario from './Formulario';


const Contacto = () => {
    return (
        <div className='bg-primary h-full overflow-hidden w-full'>
            <div className='container w-full p-5 md:px-0 md:py-16 ' >
                <div className="flex rounded-lg  md:max-w-xl ">
                    <div className="inline-flex flex-col justify-start  gap-5">
                        <h2 className="mb-2 text-[31px] text-secondary font-bold opacity-90">
                            Contacto
                        </h2>
                        <p className="text-[20px] text-secondary opacity-50  w-full text-justify">
                            Conéctate con nosotros y descubre cómo podemos transformar
                            tu negocio. Estamos aquí para escucharte y ayudarte a alcanzar
                            tus objetivos.
                        </p>
                    </div>
                </div>
                <Formulario />
            </div>


        </div>
    )
}

export default Contacto