import React from 'react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';

const SocialLinks = () => {
  const socialNetworks = [
    {
      Icon: FaInstagram,
      name: 'Instagram',
      url: 'https://instagram.com/'
    },
    {
      Icon: FaLinkedin,
      name: 'LinkedIn',
      url: 'https://linkedin.com/'
    }
  ];

  return (
    <div className="flex md:flex-row gap-4">
      {socialNetworks.map(({ Icon, name, url }) => (
        <a
          key={name}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="
            flex 
            items-center 
            justify-center 
            gap-3 
            bg-black 
            text-primary
            py-2
            px-4 
            border 
            border-white 
            text-base 
            rounded-none 
            w-full 
            md:w-min 
            transition-all 
            duration-300 
            group 
            relative 
            overflow-hidden 
            hover:bg-white 
            hover:text-black 
            hover:border-black
          "
        >
          {/* Efecto de línea en hover */}
          <span 
            className="
              absolute 
              bottom-0 
              left-0 
              h-0.5 
              bg-white 
              transition-all 
              duration-300 
              group-hover:w-full 
              group-hover:bg-black 
              w-0
            "
          ></span>

          {/* <Icon 
            className="
              text-xl 
              transition-transform 
              duration-300 
              group-hover:scale-110
            " 
          /> */}
          
          <span className="font-medium tracking-wider">
            {name}
          </span>
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;