import { useState } from "react"
import { createContext } from "react"

const ColorContext = createContext("")


const BGColorProvider = ({ children }) => {

    const [bgColor, setBgColor] = useState("white")

    return (
        <ColorContext.Provider value={{ bgColor, setBgColor }} >
            {children}
        </ColorContext.Provider>
    )
}

export { BGColorProvider }

export default ColorContext