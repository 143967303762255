import React, { useContext, useState } from 'react'
import Cta from '../ui/cta'
import ColorContext from '../providers/ColorContainer'
import { SlArrowDown } from 'react-icons/sl'
import CursorContext from '../providers/Cursor'
import { AiOutlineClose } from 'react-icons/ai'
import { Button } from 'antd'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'


function Navbar() {
    const { bgColor } = useContext(ColorContext)
    const [openMenu, setOpenMenu] = useState(false)
    const { selectEnter, selectExit, navBarEnter, navBarExit } = useContext(CursorContext)
    return (
        <div className='w-full fixed pt-[33px] z-index-99  '>
            <div className='container flex justify-between px-5 md:px-0'>
                <NavLink onMouseEnter={selectEnter} onMouseLeave={selectExit} to={'/'}>
                    <img src={
                        bgColor == 'white' ?
                            'https://awna.s3.sa-east-1.amazonaws.com/Vector.png' :
                            'https://awna.s3.sa-east-1.amazonaws.com/LogoWhite.png'
                    } className='w-[54px] h-[40px]' />
                </NavLink>
                <div className='flex gap-10 items-center'>
                    <div className='hidden md:block'>
                        <Cta text={'Contacto'} changeColor link={'/contact'} />
                    </div>
                    {
                        !openMenu ?
                            <div onClick={() => setOpenMenu(true)} onMouseEnter={selectEnter} onMouseLeave={selectExit} className='flex gap-2 items-center '>
                                <SlArrowDown color={
                                    bgColor == 'white' ?
                                        'black' :
                                        'white'
                                } />
                                <p style={{
                                    color: bgColor == 'white' ? 'black' : 'white'
                                }}>
                                    Menú
                                </p>
                            </div>
                            :
                            <div onClick={() => setOpenMenu(false)} className='z-index-999999 text-white relative'>
                                <AiOutlineClose size={30} />
                            </div>
                    }
                </div>
            </div>
            <div onMouseEnter={navBarEnter} onMouseLeave={navBarExit} className={openMenu ? 'fixed left-0 top-0 w-[100%] h-full bg-black ease-in-out duration-500 bgmenu' : 'fixed left-[-100%]'}>
                {
                    openMenu &&
                    <div className='container flex flex-col-reverse md:flex-row items-center justify-center md:justify-between h-[90%] gap-10 px-5 md:px-0'>
                        <div className='md:grid flex gap-5 md:gap-0 flex-col grid-rows-3 md:justify-between md:h-full '>
                            <p className='text-primary opacity-50 row-start-2 text-justify text-base     md:text-xl md:w-[45%]'>
                                Nos especializamos en la digitalización
                                de procesos empresariales, ofreciendo
                                consultoría que impulsa la eficiencia
                                y la innovación.
                            </p>
                            <div className='flex flex-col row-start-3 gap-5 justify-end md:w-[40%]'>
                                <NavLink to={'/contact'}>
                                    <Button className='bg-primary rounded-none !text-secondary text-base py-5 md:w-min px-10 cursor-none '>
                                        contacto@awna.cl
                                    </Button>
                                </NavLink>
                                <div className='flex flex-row gap-5 md:gap-10'>
                                    <Button className='!bg-transparent !text-primary py-4 px-5 rounded-none text-base cursor-none '>
                                        Instagram
                                    </Button>
                                    <Button className='!bg-transparent !text-primary py-4 px-5 rounded-none text-base cursor-none '>
                                        Linkedin
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col md:gap-14 2xl:gap-24 gap-5 justify-end h-full w-full'>
                            <NavLink to={'/'}>
                                <motion.p className='text-white text-3xl lg:text-[90px] 2xl:text-[132px] hover:opacity-100 opacity-50  md:blur-sm hover:blur-none text-start md:text-end'>HOME</motion.p>
                            </NavLink>
                            <NavLink to={'/aboutus'}>
                                <motion.p className='text-white text-3xl lg:text-[90px] 2xl:text-[132px] hover:opacity-100 opacity-50  md:blur-sm hover:blur-none text-start md:text-end'>NOSOTROS</motion.p>
                            </NavLink>
                            <NavLink to={'/services'}>
                                <motion.p className='text-white text-3xl lg:text-[90px] 2xl:text-[132px] hover:opacity-100 opacity-50  md:blur-sm hover:blur-none text-start md:text-end'>SERVICIOS</motion.p>
                            </NavLink>
                            {/* <motion.p className='text-white text-3xl lg:text-[90px] 2xl:text-[132px] hover:opacity-100 opacity-50  md:blur-sm hover:blur-none text-start md:text-end'>BLOG</motion.p>
                            <motion.p className='text-white text-3xl lg:text-[90px] 2xl:text-[132px] hover:opacity-100 opacity-50  md:blur-sm hover:blur-none text-start md:text-end'>CLIENTES</motion.p> */}
                            <NavLink to={'/contact'}>
                                <motion.p className='text-white text-3xl lg:text-[90px] 2xl:text-[132px] hover:opacity-100 opacity-50  md:blur-sm hover:blur-none text-start md:text-end'>CONTACTO</motion.p>
                            </NavLink>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

export default Navbar