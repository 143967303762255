import React from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

function FooterItem({ nombre, link }) {
  return (
    <NavLink to={link}>
      <motion.li
        initial={{ translateY: 10, scale: 1 }}
        whileHover={{ translateY: 20, scale: 1.1 }}
        className="md:text-4xl text-2xl  w-min whitespace-nowrap text-primary "
      >
        {nombre}
      </motion.li>
    </NavLink>
  );
}

export default FooterItem;
