import React from 'react'
import Hero from '../Sections/Home/Hero'
import AboutUs from '../Sections/Home/AboutUs'
import ContactoPage from '../Sections/Contact/ContactoPage'
import Footer from '../Sections/Home/Footer'
import Container from '../Layout/Container'
import Servicios from '../Sections/Home/Servicios'
import Contacto from '../Sections/Home/Contacto'
import Clients from '../Sections/Home/Clients'

function Home() {
    return (
        <Container>
            <Hero />
            <AboutUs />
            <Clients />
            <Servicios />
            <Contacto />
            <Footer />
        </Container>

    )
}

export default Home