import React, { useContext, useState } from 'react';
import FormCalendar from '../../ui/FormCalendar';
import FormArea from '../../ui/FormArea';
import Cta from '../../ui/cta';
import CursorContext from '../../providers/Cursor';
import emailjs from 'emailjs-com';

const Formulario = () => {
    const { selectEnter, selectExit } = useContext(CursorContext);
    const [formData, setFormData] = useState({
        nombre: "",
        email: "",
        mensaje: "",
        empresa: "",
        area: "",
    });
    const [status, setStatus] = useState({
        loading: false,
        error: null,
        success: false,
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleAreaChange = (selectedArea) => {
        setFormData((prevData) => ({
            ...prevData,
            area: selectedArea, 
        }));
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
        return emailRegex.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Verificar que todos los campos requeridos estén llenos
        const { nombre, email, mensaje, empresa } = formData;
        if (!nombre || !email || !mensaje || !empresa) {
            setStatus({
                loading: false,
                error: "Por favor, completa todos los campos.",
                success: false,
            });
            return;
        }

        // Validar formato de email
        if (!validateEmail(email)) {
            setStatus({
                loading: false,
                error: "Por favor, ingresa un email válido.",
                success: false,
            });
            return;
        }

        
        // Enviar el evento a Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'formSubmit',
            formData: { // Puedes agregar más datos si es necesario
                nombre,
                email,
                empresa,
                mensaje,
                area: formData.area,
            }
        });

        setStatus({ loading: true, error: null, success: false });

        // Configuración de EmailJS
        const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const userID = process.env.REACT_APP_EMAILJS_USER_ID;
        emailjs
            .send(
                serviceID,
                templateID,
                {
                    contact_name: formData.nombre,
                    contact_email: formData.email,
                    message: formData.mensaje,
                    contact_company: formData.empresa,
                    contact_area: formData.area,
                },
                userID
            )
            .then((response) => {
                console.log("SUCCESS!", response.status, response.text);
                setStatus({ loading: false, error: null, success: true });
                setFormData({ nombre: "", email: "", mensaje: "", empresa: "" });
            })
            .catch((err) => {
                console.error("FAILED...", err);
                setStatus({
                    loading: false,
                    error: "Error al enviar el mensaje",
                    success: false,
                });
            });
    };

    return (
        <div className='flex justify-right w-full overflow-hidden py-5'>
            <form className="w-full" onSubmit={handleSubmit}>
                <div className="flex items-center border-dashed border-b-2 py-2 opacity-50">
                    <input
                        onMouseEnter={selectEnter} onMouseLeave={selectExit}
                        className="bg-transparent border-none w-full text-2xl md:text-5xl font-bold text-gray-700 
                            mr-3 py-1 px-2 leading-tight focus:outline-none text-right placeholder:text-black placeholder:opacity-75"
                        type="text"
                        placeholder="NOMBRE"
                        aria-label="name"
                        id='nombre'
                        name='nombre'
                        value={formData.nombre}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex items-center border-dashed border-b-2 py-2 opacity-50">
                    <input
                        onMouseEnter={selectEnter} onMouseLeave={selectExit}
                        className="appearance-none bg-transparent border-none w-full text-2xl md:text-5xl font-bold text-gray-700 
                        mr-3 py-1 px-2 leading-tight focus:outline-none text-right placeholder:text-black placeholder:opacity-75"
                        type="text" placeholder="EMPRESA"
                        aria-label="empresa"
                        id='empresa'
                        name='empresa'
                        value={formData.empresa}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex items-center border-dashed border-b-2 py-2 opacity-50">
                    <input
                        onMouseEnter={selectEnter} onMouseLeave={selectExit}
                        className="appearance-none bg-transparent border-none w-full text-2xl md:text-5xl font-bold text-gray-700 
                        mr-3 py-1 px-2 leading-tight focus:outline-none text-right placeholder:text-black placeholder:opacity-75"
                        type="email"
                        placeholder="EMAIL"
                        aria-label="email"
                        id='email'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                {/* -------Buttons */}
                <div className='flex md:space-x-4 mt-10 items-center md:justify-end'>
                    <div className='flex w-full md:w-1/2 justify-end'>
                        <FormArea onAreaChange={handleAreaChange}/>
                    </div>
                </div>
                <div className='flex mx-2 mt-10'>
                    <textarea
                        onMouseEnter={selectEnter} onMouseLeave={selectExit}
                        className="w-full resize-none h-64 py-10 px-10 text-right placeholder:text-right"
                        placeholder='Cuentanos acerca de tu proyecto y te ayudaremos a crecer.' 
                        id='mensaje'
                        name='mensaje'
                        value={formData.mensaje}
                        onChange={handleChange}
                        required>
                    </textarea>
                </div>
                <div className="flex justify-end mt-5">
                    <Cta
                        text={status.loading ? "Enviando..." : "Enviar"}
                        changeColor={false}
                        onClick={handleSubmit}
                    />
                </div>
                {status.error && (
                    <div className="text-red-600 text-sm mt-2 text-right">
                        {status.error}
                    </div>
                )}
                {status.success && (
                    <div className="text-green-600 text-sm mt-2 text-right">
                        Mensaje enviado con éxito
                    </div>
                )}
            </form>
        </div>
    );
}

export default Formulario;