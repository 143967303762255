import React from "react";
import { delay, motion } from "framer-motion";
import { useAnimate } from "framer-motion";
import HoverImg from "../../assets/img/Hoverimagen.png";

function ServiceItem({ nombre, img, index }) {
  const [scope, animate] = useAnimate();
  const isMobile = window.innerWidth < 768;

  const handleHover = () => {
    if (!img) return;
    animate("img", { opacity: 1 }, { duration: 0.5, ease: "easeInOut" });
  };

  const handleHoverEnd = () => {
    if (!img) return;
    animate("img", {
      opacity: 0,
    });
  };

  const attributesLi = {
    whileHover: { translateX: isMobile ? 0 : 50, opacity: 1 },
    initial: { translateX: 0, opacity: isMobile ? 1 : 0.8 },
  };

  return (
    <motion.li
      ref={scope}
      {...attributesLi}
      onHoverStart={handleHover}
      onHoverEnd={handleHoverEnd}
      className="text-[28px] md:text-[60px] w-[100%] whitespace-pre-wrap text-primary opacity-100 font-bold  md:hover:opacity-100 md:hover:blur-none  md:blur-[2px] relative"
    >
      <p className=" break-words">{nombre}</p>
      {img && (
        <motion.img
          initial={{ opacity: 0 }}
          src={HoverImg}
          alt="Hover"
          className="w-[288px] h-[288px] absolute right-0 z-index-99 "
        />
      )}
    </motion.li>
  );
}

export default ServiceItem;
