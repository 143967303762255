import React, { useContext } from 'react'
import Cta from '../../ui/cta'
import { motion } from 'framer-motion'
import CursorContext from '../../providers/Cursor'


function AboutUs() {
    // const bottomRef = React.useRef();
    // const onClick = () => {
    //     bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
    const { cursorVariant } = useContext(CursorContext)

    console.log(cursorVariant)

    return (
        <div className='h-min pb-32 flex items-center bg-primary'>
            <div className='container grid grid-cols-1 md:grid-cols-2 items-center p-5 md:p-0'>
                {
                    cursorVariant !== 'navbarSection' &&
                    <motion.div className='relative flex'
                        initial={{ x: -50 }}
                        whileInView={{ x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 1 }}
                    >
                        <motion.img src={'https://awna.s3.sa-east-1.amazonaws.com/1.png'} className='z-index-99  lg:w-[617px] h-[359px] absolute hidden md:block'
                            initial={{ x: 100 }}
                            whileInView={{ x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 1 }}

                        />
                        <motion.img src={'https://awna.s3.sa-east-1.amazonaws.com/3.png'} className='z-index-99 w-[285px] h-[359px] absolute right-12 -bottom-14 hidden  md:block'
                            initial={{ y: -500, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.5, duration: 1 }}
                        />
                        <motion.img src={'https://awna.s3.sa-east-1.amazonaws.com/2.png'} className='z-index-99 w-[285px] h-[359px] absolute -right-20 -top-10 hidden  md:block'
                            initial={{ y: 500, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.2, duration: 1 }}
                        />
                    </motion.div>
                }
                <div className='flex flex-col items-end md:mt-64'>
                    <div className='md:w-[75%] flex flex-col gap-7 md:gap-10'>
                        <p className='text-secondary text-justify text-xl opacity-50'>
                            Emergemos como una propuesta innovadora en una industria en constante evolución, donde la tecnología y la transformación digital son claves para destacar.<br/><br />
                            Somos más que una consultora; somos tu equipo extendido, preparado para desarrollar estrategias 360° que cubren todo el proceso, desde la planificación hasta la ejecución integral, sin que tengas que depender de diferentes agencias para cada proceso.
                        </p>
                        <div className='flex overflow-hidden'>
                            <Cta text={'Contacto'} link={'/contact'} />
                        </div>
                        <p className='text-secondary text-justify text-xl opacity-50 mt-10'>
                            En un mundo en constante evolución, Awna se dedica a ser el socio confiable que necesitas para adaptarte y prosperar. Creemos en la innovación como motor de desarrollo y en el poder de lo digital para transformar y expandir las oportunidades de tu empresa.
                        </p>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutUs