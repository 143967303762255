import React, { useContext, useEffect } from "react";
import Container from "../Layout/Container";
import Hero from "../Sections/AboutUs/Hero";
import Message from "../Sections/AboutUs/Message";
import Nosotros from "../Sections/AboutUs/Nosotros";
import Footer from "../Sections/Home/Footer";
import Grain4 from "../../assets/grain4.webp";
import CursorContext from "../providers/Cursor";
import BgColorContainer from "../Layout/BgColorContainer";
import PageSeparator from "../ui/PageSeparator";

function AboutUs() {
  const { blackSectionEnter, blackSectionExit } = useContext(CursorContext);

  return (
    <Container>
      <BgColorContainer>
        <Hero />
      </BgColorContainer>
      <PageSeparator />
      <Nosotros />
      <Footer />
    </Container>
  );
}

export default AboutUs;
