import { useState } from "react"
import { createContext } from "react"

const CursorContext = createContext("")


const CursorProvider = ({ children }) => {
    const [cursorVariant, setCursorVariant] = useState("default")

    const selectEnter = () => setCursorVariant("select")
    const selectExit = () => setCursorVariant("default")

    const blackSectionEnter = () => setCursorVariant("blackSection")
    const blackSectionExit = () => setCursorVariant("default")

    const navBarEnter = () => setCursorVariant("navbarSection")
    const navBarExit = () => setCursorVariant("default")

    return (
        <CursorContext.Provider value={{ cursorVariant, setCursorVariant, selectEnter, selectExit, blackSectionEnter, blackSectionExit, navBarEnter, navBarExit }} >
            {children}
        </CursorContext.Provider>
    )
}

export { CursorProvider }

export default CursorContext