import React, { useContext } from 'react'
import CursorContext from '../providers/Cursor'
import ColorContext from '../providers/ColorContainer'
import { NavLink } from 'react-router-dom'

function Cta({ text, changeColor, link, onClick }) {

    const { selectEnter, selectExit } = useContext(CursorContext)
    const { bgColor } = useContext(ColorContext)

    return (
    <NavLink to={link} onClick={onClick}>

            <div onMouseEnter={selectEnter} onMouseLeave={selectExit}
                className={
                    bgColor == 'black' && changeColor ?
                        'bg-primary hover:bg-secondary text-center px-5 py-2 text-secondary hover:text-primary  minText w-min' :
                        'bg-secondary hover:bg-primary text-center px-5 py-2 text-primary hover:text-secondary  minText w-min'
                }>
                {text}
            </div>
        </NavLink>
    )
}

export default Cta