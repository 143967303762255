import React from 'react';
import Video from '../../../assets/footer2.webp'
import Grain from '../../../assets/GrainServices.webp';
import Grain2 from '../../../assets/GrainServices2.webp';
import Graphics from '../../../assets/GraficoBubble.webp';
import PageSeparator from '../../ui/PageSeparator';


function Hero () {
  return (
    <div className='w-full h-full bg-primary relative overflow-auto'>
      <img src={"https://awna.s3.sa-east-1.amazonaws.com/GrainServices.webp"} className='absolute z-index-0  w-full bg-no-repeat opacity-60' />
      <div className='container p-5 md:p-0'>
        <div className='w-[300px] md:w-[1520px] mt-20 md:mt-[270px] ml-[8px] md:ml-[1px]  h-[273px] gap-3' >
          <h1 className='text-[25px] md:text-[70px] text-black opacity-40 leading-relaxed md:leading-[68px] '>
            Nos dedicamos con pasión a ser el aliado estratégico que
            nuestros clientes necesitan para crecer en la era digital.</h1>
        </div>
      </div>
      {/* <img src={"https://awna.s3.sa-east-1.amazonaws.com/footer2.webp"} className='absolute z-index-1  w-full bg-no-repeat hidden md:block' /> */}
      <PageSeparator />
      {/* <div className='inline-flex w-full bg-black h-[100px] mt-[390px]'></div> */}
      <img src={"https://awna.s3.sa-east-1.amazonaws.com/GrainServices2.webp"} className='absolute z-60 h-[100vh] w-full bg-no-repeat mt-[390px] hidden md:block' />
      <div className='h-[600px] md:h-[1500px] overflow-x-hidden'>
        <div className='w-[300px] md:w-[362px] h-[247px] ml-8 md:ml-[150px] mt-52 md:mt-[500px] mb-32 md:mb-0'>
          <p className='text-[15px] md:text-[20px] text-black  opacity-60 md:opacity-40 text-justify'>
            Nos especializamos en comprender profundamente las necesidades y en interpretar
            con precisión y audacia los requerimientos específicos de cada negocio. Creemos en la innovación
            como motor de desarrollo y en el poder de lo digital para transformar
            y expandir las oportunidades de tu empresa.
          </p>
          <img src={"https://awna.s3.sa-east-1.amazonaws.com/NewBubbleServices.webp"} className='absolute z-80  w-[375.6px] h-[267.432px] md:w-[1044px] md:h-[742px] -ml-6 md:ml-64 -mt-[475px] md:-mt-[175px]' />
        </div>
        <img src={"https://awna.s3.sa-east-1.amazonaws.com/metodologi%CC%81a.svg"} className="w-full px-4 -mt-[580px] md:-mt-0" alt="metodologia" />
        {/* <h2 className="text-[340px] font-[1] blur-[1.8px] text-transparent bg-clip-text bg-black opacity-35  ml-6 z-0  overflow-auto pb-96">
        metodología
      </h2> */}
        </div>
      </div>

  )
}

export default Hero