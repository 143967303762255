import React, { StrictMode } from 'react';
import * as ReactDOMClient from "react-dom/client";
import './index.css';
import App from './App';
import { CursorProvider } from './components/providers/Cursor';
import { BGColorProvider } from './components/providers/ColorContainer';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES'
import Theme from './Data/Theme.json';

function AppWithUI() {


  return (
    <ConfigProvider locale={esES} theme={Theme}>
      <CursorProvider>
        <BGColorProvider>
          <App />
        </BGColorProvider>
      </CursorProvider>
    </ConfigProvider>

  );
}


const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);
root.render(<AppWithUI />);
