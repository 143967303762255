import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay'
import ClientsData from '../../../Data/Clientes.json'
import { Autoplay } from 'swiper/modules';

function Clients() {
    return (
        <div className='w-full bg-primary'>
            <div className='border-b-2 border-secondary opacity-50 h-1 border-dashed'></div>

            <div className='px-4 sm:px-6 md:px-8 py-10 sm:py-12 md:py-16 smooth-slider'>
                <Swiper
                    className='w-full'
                    spaceBetween={20}
                    breakpoints={{
                        // Configuración responsiva para diferentes tamaños de pantalla
                        0: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                        1440: {
                            slidesPerView: 5,
                            spaceBetween: 40,
                        }
                    }}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                        waitForTransition: true
                    }}
                    speed={3000}
                    loop={true}
                >
                    {ClientsData.map((client, index) => (
                        <SwiperSlide 
                            key={index} 
                            className='
                                flex 
                                justify-center 
                                items-center 
                                h-full
                            '
                        >
                            <div className='
                                flex 
                                justify-center 
                                items-center 
                                w-full 
                                h-full
                            '>
                                <img 
                                    src={client.url} 
                                    alt={client.name} 
                                    className='
                                        w-full 
                                        max-w-[180px]
                                        sm:max-w-[200px] 
                                        md:max-w-[220px] 
                                        lg:max-w-[250px]
                                        h-auto
                                        object-contain
                                        mx-auto
                                        p-2
                                        hover:scale-105
                                        transition-transform 
                                        duration-300 
                                        ease-in-out
                                    '
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Clients;